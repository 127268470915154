<template>
    <div id="main">
        <div class="section">
            <div>
                <div class="handle">修改密码</div>
                <div class="inputBox">
                    <div class="box">
                        <span>当前密码：</span>
                        <el-input
                                placeholder="请输入当前密码"
                                v-model="oldpassword"
                                maxlength="20"
                                type="password"
                                auto-complete="new-password"
                        ></el-input>
                    </div>
                    <div class="box">
                        <span>新密码：</span>
                        <el-input
                                placeholder="请输入新密码"
                                v-model="newpassword"
                                maxlength="20"
                                type="password"
                                auto-complete="new-password"
                        ></el-input>
                    </div>
                </div>
                <div class="btnBox">
                    <el-button @click="changePass()">确 定</el-button>
                </div>
            </div>
        </div>
        <change-pwd
                :pwdDialogFu="pwdDialogFu"
                @closePwdDialog="closePwdDialog"
        ></change-pwd>
    </div>
</template>

<script>
    import ChangePwd from "c/index/changePwd/ChangePwd";
    import {changePass} from "../../../request/index/login";

    export default {
        name: "changePassword",
        data() {
            return {
                oldpassword: "",
                newpassword: "",
                pwdDialogFu: false,
            };
        },
        methods: {
            closePwdDialog(value) {
                console.log(value);
                this.pwdDialogFu = value;
                // 清除本地的换成
                let studentId = window.localStorage.getItem("student_id");
                for (let k in window.localStorage) {
                    if (k != "exam_notice_" + studentId && k != "agree-login") {
                        window.localStorage.removeItem(k);
                    }
                }
                this.$store.state.token = "";
                this.$store.state.isLogin = 0;
                // 使用编程式导航跳转到登录页面
                this.$router.push("/login");
            },
            async changePass() {
                if (this.oldpassword == "") {
                    return this.$message.error("请输入旧密码");
                }
                if (this.newpassword == "") {
                    return this.$message.error("请输入新密码");
                }
                const {data: res} = await changePass({
                    oldPassword: this.oldpassword,
                    password: this.newpassword,
                });
                console.log(res);
                if (res.code !== 200) return this.$message.error(res.message);
                this.pwdDialogFu = true;
            },
        },
        components: {
            ChangePwd,
        },
    };
</script>

<style scoped lang="scss">
    #main {
        width: 100%;
        overflow: auto;
        // min-height: calc(100%-160px);
        min-height: 80%;

        .section {
            max-width: 1300px;
            padding: 0 50px;
            margin: auto;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > div {
                width: 100%;
                background: #fff;
                text-align: center;
                padding-bottom: 50px;
            }

            .handle {
                text-align: center;
                font-size: 28px;
                padding-top: 20px;
            }

            .inputBox {
                width: 100%;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                .box {
                    position: relative;
                    height: 48px;
                    line-height: 48px;
                    margin-top: 20px;

                    ::v-deep .el-input__inner {
                        width: 300px;
                        height: 48px;
                    }

                    span {
                        position: absolute;
                        left: -110px;
                        width: 100px;
                        text-align: right;
                    }
                }
            }

            .btnBox {
                margin-top: 50px;
            }
        }
    }

    ::v-deep .el-button {
        background: #563279;
        color: #fff;
        width: 140px;
        height: 48px;
    }
</style>

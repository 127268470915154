<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="520px"
    top="0"
    @open="confirm"
    :close-on-click-modal="false"
  >
    <div class="info_3">
      修改密码成功！
    </div>
    <div class="info_2">
      将于
      <span style="font-size:18px;font-weight: bold;color:#56327a;">{{
        time
      }}</span>
      秒钟后返回首页！
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ChangePwd",
  props: {
    pwdDialogFu: Boolean,
  },
  data() {
    return {
      dialogVisible: this.pwdDialogFu,
      time: "",
    };
  },
  methods: {
    confirm() {
      this.time = 5;
      let timerInt, timerTim;
      clearTimeout(timerTim);
      clearInterval(timerInt);
      // 倒计时
      timerInt = setInterval(() => {
        if (this.time <= 0) {
          return clearInterval(timerInt);
        }
        this.time--;
      }, 1000);

      // 指定时间关闭退出
      timerTim = setTimeout(() => {
        clearTimeout(timerTim);
        this.cancelBtn();
      }, 5000);
    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closePwdDialog", this.dialogVisible);
    },
  },
  watch: {
    pwdDialogFu() {
      this.dialogVisible = this.pwdDialogFu;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
::v-deep .el-dialog__header {
  height: 60px !important;
  line-height: 60px !important;
}
::v-deep .el-dialog__close::before {
  content: "";
}
.info_2 {
  text-align: center;
  margin-top: 20px;
}
</style>
